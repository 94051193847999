import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { DEFAULT_DURATION, DEFAULT_EASING } from '../config'

gsap.registerPlugin(ScrollToPlugin)

export function scrollTo(y, { offset = 0, animate = true, onScrollStart, onScrollEnd } = {}) {
  const action = animate ? 'to' : 'set'

  if (typeof onScrollStart === 'function') onScrollStart()

  gsap[action](window, {
    scrollTo: { y, offsetY: offset },
    duration: DEFAULT_DURATION,
    ease: DEFAULT_EASING,
    onComplete: onScrollEnd
  })
}

export function listenDOM() {
  const anchors = document.querySelectorAll('.js-scroll-to')

  const handleClick = e => {
    e.preventDefault()
    const anchor = e.target.closest('a')
    if (!anchor) return
    scrollTo(anchor.getAttribute('href'))
  }

  anchors.forEach(anchor => anchor.addEventListener('click', handleClick))

  return {
    destroy: () => anchors.forEach(anchor => anchor.removeEventListener('click', handleClick))
  }
}
