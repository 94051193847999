// import gsap from 'gsap'
import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper'
import { DESKTOP_BREAKPOINT, CAROUSEL_OPTIONS } from '../../config'

Swiper.use([Pagination, EffectFade, Autoplay])

class CarouselSimple {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.carousel = new Swiper(this.el, {
      ...CAROUSEL_OPTIONS,
      navigation: {
        prevEl: '.carousel__arrow_small--prev',
        nextEl: '.carousel__arrow_small--next'
      },
      loop: true,
      pagination: false,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })

    this.device = window.innerWidth < DESKTOP_BREAKPOINT ? 'mobile' : 'desktop'

    this.revealContent()
    // this.scrollTo()

    window.addEventListener('resize', this.handleResize)
  }

  /* scrollTo() {
    const btn = document.querySelector('.js-scroll-to')
    btn.addEventListener('click', () => {
      gsap.to(window, {
        duration: .5,
        scrollTo: '#target'
      })
    })
  } */

  revealContent() {
    // this.el.classList.add('revealed')
    this.carousel.params.autoplay.delay = 12000
    this.carousel.autoplay.start()
  }

  handleResize = () => {
    const device = window.innerWidth < DESKTOP_BREAKPOINT ? 'mobile' : 'desktop'

    if (device !== this.device) {

      if (this.delayedContentReveal) {
        clearTimeout(this.delayedContentReveal)
      }

      this.device = device

      if (this.device === 'mobile') {
        // this.el.classList.add('revealed')
      } else {
        // this.el.classList.remove('revealed')
      }
    }
  }
}

export default CarouselSimple
