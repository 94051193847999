import Swiper, { Navigation, Pagination } from 'swiper'
import { CAROUSEL_OPTIONS, DEFAULT_EASING } from '../../config'

Swiper.use([Navigation, Pagination])

class WhyChoose {
  constructor() {
    this.ui = {
      carousels: document.querySelectorAll('.carousel')
    }

    this.ui.carousels.forEach(single => {
      new Swiper(single, CAROUSEL_OPTIONS)
    })
  }
}

export default WhyChoose
