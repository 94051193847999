import Swiper, { Navigation, Pagination } from 'swiper'
import { CAROUSEL_OPTIONS, DEFAULT_EASING } from '../../config'
import Visual from '../../components/Visual'
import Video from '../../components/Video'
import CarouselSimple from '../../components/CarouselSimple'
import Gif from '../../components/GIF'

Swiper.use([Navigation, Pagination])

class SingleSolution {
  constructor() {
    this.ui = {
      carousel: document.querySelector('.carousel')
    }
    this.carousel = new Swiper(this.ui.carousel, CAROUSEL_OPTIONS)
    this.visuals = Array.from(document.querySelectorAll('.visual')).map(el => new Visual(el))
    new Video()
    this.gifs = Array.from(document.querySelectorAll('.gif-container')).map(el => new Gif(el))
    this.carouselSimple = Array.from(document.querySelectorAll('.js-carousel-simple')).map(el => new CarouselSimple(el))
  }
}

export default SingleSolution
