export const DESKTOP_BREAKPOINT = 1024

export const DEFAULT_EASING = 'power3.out'

export const DEFAULT_DURATION = 0.6

export const CAROUSEL_OPTIONS = {
  touchEventsTarget: 'wrapper',
  touchStartPreventDefault: false,
  preventInteractionOnTransition: true,
  speed: 600,
  navigation: {
    prevEl: '.carousel__arrow--prev',
    nextEl: '.carousel__arrow--next'
  },
  pagination: {
    el: '.carousel__pagination',
    type: 'bullets',
    clickable: true
  }
}
