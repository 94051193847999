import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper'
import { DESKTOP_BREAKPOINT, CAROUSEL_OPTIONS } from '../../config'

Swiper.use([Pagination, EffectFade, Autoplay])

class FeaturedHero {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.ui = {
      video: this.el.querySelector('.hero__background'),
      content: this.el.querySelector('.hero__content'),
      carousel: this.el.querySelector('.carousel')
    }
    this.carousel = new Swiper(this.ui.carousel, {
      ...CAROUSEL_OPTIONS,
      navigation: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })

    this.carousel.on('slideChange', (swiper) => {
      const images = document.querySelectorAll('.bg-carousel__image')

      if (images) {
        images.forEach(el => {
          const ref = el.getAttribute('data-ref')

          if (ref === `slide-${swiper.activeIndex}`) {
            el.classList.add('is-shown')
          } else {
            el.classList.remove('is-shown')
          }

        })
      }
    })

    this.device = window.innerWidth < DESKTOP_BREAKPOINT ? 'mobile' : 'desktop'
    this.playedVideo = false

    //this.ui.video.addEventListener('canplay', this.handleVideoLoad)
    //this.ui.video.addEventListener('ended', this.handleVideoEnd)
    //this.ui.video.addEventListener('timeupdate', this.handleContentReveal)

    this.revealContent()
    //this.updateVideo()
    this.scrollTo()

    window.addEventListener('resize', this.handleResize)
  }

  scrollTo() {
    const btn = document.querySelector('.js-scroll-to')
    btn.addEventListener('click', () => {
      gsap.to(window, {
        duration: .5,
        scrollTo: '#target'
      })
    })
  }

  updateVideo() {
    const src = this.ui.video.getAttribute(`data-src-${this.device}`)

    if (!src) return

    this.ui.video.innerHTML = `<source type="video/mp4" src="${src}" />`
    this.ui.video.load()
  }

  revealContent() {
    this.ui.content.classList.add('revealed')
    this.carousel.params.autoplay.delay = 12000
    this.carousel.autoplay.start()
  }

  handleVideoLoad = () => {
    if (this.playedVideo) {
      this.ui.video.currentTime = this.ui.video.duration
      this.revealContent()
    } else {
      this.ui.video.play()
      if (window.innerWidth < DESKTOP_BREAKPOINT) {
        this.revealContent()
      }
    }
  }

  handleVideoEnd = () => {
    this.playedVideo = true
  }

  handleResize = () => {
    const device = window.innerWidth < DESKTOP_BREAKPOINT ? 'mobile' : 'desktop'

    if (device !== this.device) {

      if (this.delayedContentReveal) {
        clearTimeout(this.delayedContentReveal)
      }

      this.device = device

      if (this.device === 'mobile') {
        this.ui.content.classList.add('revealed')
      } else {
        this.ui.content.classList.remove('revealed')
      }

      this.updateVideo()
    }
  }

  handleContentReveal = () => {
    if (this.ui.video.currentTime >= this.options.revealDelay) {
      this.revealContent()
    }
  }
}

export default FeaturedHero
