import { listenDOM } from '../../helpers/scroll-to'
import $ from 'jquery'
import { load } from 'recaptcha-v3'

const validityRules = {
  _: string => string.trim().length > 0,
  email: string => /^[a-zA-Z_-]([\w.-]?[a-zA-Z0-9])*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){2,})+$/.test( string )
}

function isValidValue (value, type) {
  const fn = typeof validityRules[type] === 'function' ? validityRules[type] : validityRules._
  return fn(value)
}

class Partner {
  constructor() {
    this.anchorsListener = listenDOM()
    this.dataForm = {}
    this.$form = document.querySelector('.contact-form__form')
    this.$submit = this.$form.querySelector('.js-submit-form')
    this.$inputs = Array.from(this.$form.querySelectorAll('.contact-form__input'))
    this.$privacy = this.$form.querySelector('#privacy')
    this.$spinner = this.$form.querySelector('.contact-form__spinner')
    this.onChangeInput()
    this.submitForm()

    if( Array.from(this.$inputs).filter(field => field.value.length > 0).length > 0 ){
      this.checkEmptyValue()
      this.checkErrors()
    }
  }

  onChangeInput() {

    this.$inputs.forEach(el => {
      el.addEventListener('keyup', () => {
        this.checkEmptyValue()
        this.checkErrors(el)
      })
    })

    this.$privacy.addEventListener('change', () => {
      this.checkEmptyValue()
    })

  }

  checkEmptyValue() {
    const errorsArray = ['privacy']

    this.$inputs.forEach(el => {
      if (!el.value) {
        errorsArray.push(el.id)
        el.closest('.contact-form__field-wrapper').classList.remove('has-value')
      } else {
        el.closest('.contact-form__field-wrapper').classList.add('has-value')
        if( isValidValue(el.value, el.type) ) {
          this.removeElement(errorsArray, el.id)
        }
      }
    })

    if (this.$privacy.checked) {
      this.removeElement(errorsArray, 'privacy')
    }

    if (errorsArray.length === 0) {
      this.$submit.classList.remove('is-disabled')
    } else {
      this.$submit.classList.add('is-disabled')
    }
  }

  removeElement(array, elem) {
    var index = array.indexOf(elem)
    if (index > -1) {
      array.splice(index, 1)
    }
  }

  checkErrors(field = null) {
    let errors = false

    const checkFieldError = el => {
      const $wrapper = el.closest('.contact-form__field-wrapper')
      const $error = $wrapper.querySelector('.contact-form__error:not(.contact-form__error--rule)')
      const $errorRule = $wrapper.querySelector('.contact-form__error--rule')

      if (el.classList.contains('is-checkbox')) {
        if (el.checked) {
          this.dataForm[el.id] = 'Accetto'
        } else {
          if (el.classList.contains('is-required')) {
            $wrapper.classList.add('has-error')
            $error.style.display = 'block'
            errors = true
          }
        }
      } else {
        if (isValidValue(el.value, el.type)) {
          this.dataForm[el.id] = el.value
        } else {
          if (el.classList.contains('is-required')) {
            $wrapper.classList.add('has-error')
            if( !validityRules._(el.value) ){
              if($errorRule) $errorRule.style.display = 'none'
              if($error) $error.style.display = 'block'
            } else if( el.type in validityRules ) {
              if($error) $error.style.display = 'none'
              if($errorRule) $errorRule.style.display = 'block'
            }
            errors = true
          }
        }
      }
    }

    if( field ){
      checkFieldError(field)
      if( !errors ){
        const $wrapper = field.closest('.contact-form__field-wrapper')
        $wrapper.classList.remove('has-error')
        $wrapper.querySelectorAll('.contact-form__error').forEach(el => {
          el.style.display = 'none'
        })
      }
    } else {
      document.querySelectorAll('.js-get-value').forEach(checkFieldError)
      if( !errors ){
        document.querySelectorAll('.contact-form__error').forEach(el => {
          el.closest('.contact-form__field-wrapper').classList.remove('has-error')
          el.style.display = 'none'
        })
      }
    }

    return errors
  }

  submitForm() {
    this.$form.addEventListener('submit', event => {
      event.preventDefault()
      const hasErrors = this.checkErrors()

      if (!hasErrors) {
        load('6LdDf4saAAAAAOqrhGOapmbcU1oZdplbBN6R1flW').then(recaptcha => {
          recaptcha.execute(this.sendRequest()).then(token => {
            console.log(token) // Will print the token
          })
        })
      }
    })
  }

  sendRequest() {
    const self = this
    
    $.ajax({
      type: 'POST',
      url: 'services/sendmail.php',
      data: this.dataForm,
      beforeSend: function () {
        self.$spinner.style.opacity = 1
      },
      success: function(response) {
        self.$spinner.style.display = 'none'
        if (response) {
          document.querySelector('.partner-title').style.display = 'none'
          self.$form.style.display = 'none'
          document.querySelector('.contact-form__success').style.display = 'block'
        } else {
          document.querySelector('.is-generic-error').style.display = 'block'
        }
      },
      error: function(xhr, status, error){
        document.querySelector('.is-generic-error').style.display = 'block'
        console.log(error)
      }
    })
  }
}


export default Partner
