class Inspirations {
  constructor() {

    var items = document.querySelectorAll('.filter-sections article')
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const cat = urlParams.get('cat')

    if (cat) {
      each('.view', function(e) {
        e.classList.remove('view')
      })
      animate(document.querySelectorAll('.' + cat))
      document.querySelector(`.filter-link[data-category=${cat}]`).classList.add('is-selected')
    } else {
      animate(Array.prototype.slice.call(items))
    }

    // filter on click
    each('.filter-links li a.filter-link', function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault()
        const starting = document.querySelector('.filter-sections.starting')
        if(starting) {
          starting.classList.remove('starting')
        }
        // Selected
        const selected = document.querySelector('.is-selected')
        if(selected) {
          selected.classList.remove('is-selected')
        }
        el.classList.add('is-selected')
        // Filter
        filterLinks(el)
      })
    })

    // filter links functions
    function filterLinks(element) {
      // get text
      var el = element.getAttribute('data-category'),
        // convert to lowercase
        linksTolowerCase = el.toLowerCase()
      // if all remove all elements
      if (el === 'all') {
        // first show all view class
        each('.view', function(e) {
          e.classList.remove('view')
        })
        // no show init animation
        animate(items)
      } else {
        // if not click all remove all elements
        each('.view', function(e) {
          e.classList.remove('view')
        })
      }
      // show animation for current elements
      animate(document.querySelectorAll('.' + linksTolowerCase))
    }

    // forech arrays
    function each(el, callback) {
      var allDivs = document.querySelectorAll(el),
        alltoArr = Array.prototype.slice.call(allDivs)
        Array.prototype.forEach.call(alltoArr, function(selector) {
          if (callback) return callback(selector)
          return null
      })
    }

    // animate function
    function animate(item) {
      (function show(counter) {
        setTimeout(function() {
          if(item[counter]) {
            item[counter].classList.add('view')
          }
            counter++
          if (counter < item.length) show(counter)
        }, 50)
      })(0)
    }

  }
}

export default Inspirations
