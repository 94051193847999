import gsap from 'gsap'
import { DESKTOP_BREAKPOINT, DEFAULT_DURATION, DEFAULT_EASING } from '../../config'

class Submenu {
  constructor(el) {
    this.el = el
    this.dropdown = this.el.querySelector('.nav__dropdown')
    this.content = this.el.querySelector('[data-subnav-content]')

    this.el.addEventListener('click', this.handleClick)
  }

  get contentHeight() {
    return this.content.clientHeight + this.content.offsetTop
  }

  handleClick = () => {
    if (window.innerWidth >= DESKTOP_BREAKPOINT) return

    if (this.el.classList.contains('open')) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.el.classList.add('open')

    gsap.to(this.dropdown, {
      height: this.contentHeight,
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASING
    })
  }

  close() {
    this.el.classList.remove('open')

    gsap.to(this.dropdown, {
      height: 0,
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASING
    })
  }

  reset() {
    gsap.set(this.dropdown, { clearProps: 'height' })
  }
}

export default Submenu
