import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { scrollTo } from '../../helpers/scroll-to'

gsap.registerPlugin(ScrollTrigger)

class AnchoredSections {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.nav = this.el.querySelector('.anchored-sections__nav')

    this.nav.addEventListener('click', this.handleNavClicks)

    ScrollTrigger.create({
      trigger: this.el,
      start: () => `-=${this.nav.clientHeight} top`,
      end: 'bottom center',
      onEnter: () => this.nav.classList.add('sticky'),
      onLeaveBack: () => this.nav.classList.remove('sticky'),
    })

    if (window.location.hash) {
      setTimeout(() => this.scrollTo(window.location.hash, false), 50)
    }
  }

  handleNavClicks = e => {
    const anchor = e.target.closest('a')
    if (!anchor) return
    e.preventDefault()
    this.scrollTo(anchor.getAttribute('href'))
  }

  scrollTo(y, animate = true) {
    scrollTo(y, {
      ...this.options,
      offset: this.nav.clientHeight,
      animate
    })
  }
}

export default AnchoredSections
