import ScrollTrigger from 'gsap/ScrollTrigger'
import Tabview from '../../components/Tabview'
import Accordion from '../../components/Accordion'

class Support {
  constructor() {
    this.faqMainTabs = new Tabview(document.querySelector('.tabview'), {
      onChange: () => ScrollTrigger.refresh(true)
    })
    this.faqGroups = Array.from(document.querySelectorAll('.accordion')).map(accordion => {
      return new Accordion(accordion)
    })
  }
}

export default Support
