import gsap from 'gsap'
import { DEFAULT_DURATION, DEFAULT_EASING } from '../../config'

class AccordionItem {

  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.summary = this.el.querySelector('.accordion__item__summary')
    this.details = this.el.querySelector('.accordion__item__details')
    this.content = this.el.querySelector('.accordion__item__content')

    if (this.options.expanded) this.expand(false)

    this.summary.addEventListener('click', this.toggle)

    window.addEventListener('resize', this.handleResize)
  }

  expand = (animate = true) => {
    const { onExpand } = this.options
    const { clientHeight: height } = this.content

    if (typeof onExpand === 'function') onExpand(this)

    gsap.killTweensOf(this.details)

    if (animate) {
      gsap.to(this.details, {
        height,
        duration: DEFAULT_DURATION,
        ease: DEFAULT_EASING
      })
    } else {
      gsap.set(this.details, { height })
    }

    this.expanded = true
    this.el.classList.add('expanded')
  }

  collapse = () => {
    const { onCollapse } = this.options

    if (typeof onCollapse === 'function') onCollapse(this)

    this.expanded = false
    this.el.classList.remove('expanded')

    gsap.to(this.details, {
      height: 0,
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASING,
      onComplete: () => {
        gsap.set(this.details, { clearProps: 'height' })
      }
    })
  }

  toggle = () => {
    if (this.expanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  handleResize = () => {
    if (!this.expanded) return
    const { clientHeight: height } = this.content
    gsap.set(this.details, { height })
  }
}

export default AccordionItem
