import FirstStep from '../../../../views/templates/steps/first-step.twig'
import SecondStep from '../../../../views/templates/steps/second-step.twig'
import ThirdStep from '../../../../views/templates/steps/third-step.twig'
import FourthStep from '../../../../views/templates/steps/fourth-step.twig'
import Mock from '../../../../mock/advanced-campaigns.json'

class AdvancedCampaigns {
  constructor() {

    this.slidesContainer = document.querySelector('.slides-container')
    this.stepsWrapper = document.querySelector('.steps')
    this.scrollAnchor = document.querySelector('#scroll-anchor')
    this.currentSlide = 0
    this.strategy = null
    this.lastStepRendered = 0
    this.pendingOperation = false
    this.stepsResumeTitles = ['', '', '', '']
    this.setpsDescriptions = Mock.stepstitles
    const self = this
    this.initialStep = null
    // Initial steps list
    document.querySelector('#first-step-wrapper').innerHTML = FirstStep({ mock: Mock.contents.steps })

    this.GoToSlide(this.currentSlide)

    document.addEventListener('click', function(e) {
      if(e.target && e.target.closest('.step')) {
        const openStep = document.querySelector('.step.active')
        if(openStep) {
          openStep.classList.remove('active')
        }
        if(!e.target.closest('.step').classList.contains('never-active')) {
          e.target.closest('.step').classList.add('active')
        }
        self.UpdateViewHeight()
      }
    })

    document.addEventListener('click', function(e) {
      if(e.target && e.target.closest('.close-button')) {
        e.target.closest('.step').classList.remove('active')
        self.UpdateViewHeight()
      }
    })

    document.querySelectorAll('[data-slide-link]').forEach(dataSlideLink => {
      dataSlideLink.addEventListener('click', function() {
        if(self.pendingOperation) {
          return
        }
        self.pendingOperation = true
        const stepTarget = this.getAttribute('data-slide-link') - 1
        if(stepTarget <= self.lastStepRendered) {
          self.currentSlide = stepTarget
          self.GoToSlide(Number(stepTarget))
          self.UpdateViewHeight()
          self.UpdateUI()
        }
        setTimeout(() => {
          self.pendingOperation = false
        }, 800)
      })
    })

    document.addEventListener('click', function(e) {
      if(e.target && e.target.closest('.prev-button')) {
        if(self.pendingOperation) {
          return
        }
        self.pendingOperation = true
        self.PrevPressed()
        setTimeout(() => {
          self.pendingOperation = false
        }, 800)
        self.UpdateViewHeight()
      }
    })

    document.addEventListener('click', function(e) {
      if(e.target && e.target.closest('.next-button')) {
        if(self.pendingOperation) {
          return
        }
        self.pendingOperation = true
        self.NextPressed()
        setTimeout(() => {
          self.pendingOperation = false
        }, 800)
        self.UpdateViewHeight()
      }
    })

    document.addEventListener('click', function(e) {
      if(e.target && e.target.closest('.proceed-button')) {
        const proceedButton = e.target.closest('.proceed-button')
        if(!self.initialStep) {
          self.initialStep = proceedButton.getAttribute('data-choice')
          document.querySelector('#second-step-wrapper').innerHTML = SecondStep({ mock: Mock.contents.steps, initialStep: self.initialStep })
          self.currentSlide = 1
          self.lastStepRendered = 1
          self.GoToSlide(1)
          self.scrollAnchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
          setTimeout(() => {
            self.UpdateViewHeight()
          }, 200)
        } else {
          const currentStep = Number(proceedButton.getAttribute('data-step'))
          switch (currentStep) {
            case 2:
              self.strategy = Number(proceedButton.getAttribute('data-strategy'))
              document.querySelector('#third-step-wrapper').innerHTML = ThirdStep({ mock: Mock.contents.steps, initialStep: self.initialStep, strategy: self.strategy })
              self.currentSlide = 2
              self.lastStepRendered = 2
              self.GoToSlide(self.currentSlide)
              self.scrollAnchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
              self.UpdateUI()
              setTimeout(() => {
                self.UpdateViewHeight()
              }, 200)
              break
            case 3:
              document.querySelector('#fourth-step-wrapper').innerHTML = FourthStep({
                mock: Mock.contents.steps,
                initialStep: self.initialStep,
                strategy: self.strategy
              })
              self.currentSlide = 3
              self.lastStepRendered = 3
              self.GoToSlide(self.currentSlide)
              self.scrollAnchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
              setTimeout(() => {
                self.UpdateViewHeight()
              }, 200)
              break
          }
        }
      }
    })

    this.stepsContainer = document.querySelector('.steps-navbar-container')
    this.stepsContainer.style.height = this.stepsContainer.clientHeight + 'px'
    this.anchorNavBar()

    window.addEventListener('scroll', () => {
      this.anchorNavBar()
    })

  }

  NextPressed() {
    if(this.currentSlide < this.lastStepRendered || this.currentSlide === 2) {
      if(this.currentSlide === 2) {
        document.querySelector('#fourth-step-wrapper').innerHTML = FourthStep({ mock: Mock.contents.steps, initialStep: this.initialStep, strategy: this.strategy })
      }
      this.currentSlide += 1
      window.goToSlide(this.currentSlide)
      this.UpdateUI()
    }
  }

  PrevPressed() {
    if(this.currentSlide) {
      this.currentSlide -= 1
      window.goToSlide(this.currentSlide)
      this.UpdateUI()
    }
  }

  GoToSlide(slide) {
    window.goToSlide(slide)
    this.UpdateUI()
  }

  UpdateUI() {
    if(this.currentSlide === this.lastStepRendered && this.currentSlide !== 2) {
      document.querySelector('a.next-button').setAttribute('disabled', true)
      document.querySelector('span.next-button')?.setAttribute('disabled', true)
    } else {
      document.querySelector('a.next-button').removeAttribute('disabled')
      document.querySelector('span.next-button')?.removeAttribute('disabled')
    }
    if(this.currentSlide <= 0) {
      document.querySelector('a.prev-button').setAttribute('disabled', true)
      document.querySelector('span.prev-button')?.setAttribute('disabled', true)
    } else {
      document.querySelector('a.prev-button').removeAttribute('disabled')
      document.querySelector('span.prev-button')?.removeAttribute('disabled')
    }
    if(this.currentSlide !== 2) {
      if(this.currentSlide < this.lastStepRendered) {
        document.querySelector('a.next-button').removeAttribute('disabled')
        document.querySelector('span.next-button')?.removeAttribute('disabled')
      } else {
        document.querySelector('a.next-button').setAttribute('disabled', true)
        document.querySelector('span.next-button')?.setAttribute('disabled', true)
      }
    }

    // Reset nav
    ['nav-1', 'nav-2', 'nav-3', 'nav-4'].forEach(className => {
      document.querySelector(`.${className}`).classList.remove('active')
    })
    const lastActiveNavStep = document.querySelector('.last-active')
    if(lastActiveNavStep) {
      lastActiveNavStep.classList.remove('last-active')
    }

    let lastStep = this.currentSlide
    while(lastStep > -1) {
      document.querySelector(`.nav-${lastStep+1}`).classList.add('active')
      if(lastStep === this.currentSlide) {
        document.querySelector(`.nav-${lastStep+1}`).classList.add('last-active')
      }
      lastStep--
    }

    if(this.currentSlide <= 0) {
      this.initialStep = null
    }

    document.querySelector('.mobile-step-title').innerHTML = document.querySelector('.last-active a').innerHTML

    const textToPrint = this.setpsDescriptions[this.currentSlide]
    document.querySelector('.slide-title').textContent = textToPrint.title
    document.querySelector('.slide-description').textContent = textToPrint.description
    if(!textToPrint.title && !textToPrint.description) {
      document.querySelector('.title-container').classList.add('hide')
      document.querySelector('.title-container').classList.remove('show')
    } else {
      document.querySelector('.title-container').classList.add('show')
      document.querySelector('.title-container').classList.remove('hide')
    }

  }

  UpdateViewHeight() {
    if(document.querySelector('.steps')) {
      this.slidesContainer.style.height = `${document.querySelector('.slide-image.active').querySelector('.steps').clientHeight + 90}px`
    }
  }

  anchorNavBar() {
    const scrollAnchor = document.querySelector('#scroll-anchor')
    const navBar = document.querySelector('#steps-navbar')
    const navBarOffsetTop = scrollAnchor.offsetTop
    const headerHeight = document.querySelector('.header').clientHeight
    const scrollY = window.scrollY

    if (scrollY >= (navBarOffsetTop - headerHeight - this.stepsContainer.clientHeight)) {
      navBar.classList.add('is-fixed')
      navBar.style.top = headerHeight + 'px'
    } else {
      navBar.classList.remove('is-fixed')
      navBar.style.top = 0
    }
  }

}


export default AdvancedCampaigns
