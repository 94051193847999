import Swiper, { Pagination } from 'swiper'
import { CAROUSEL_OPTIONS } from '../../config'

Swiper.use([Pagination])

class Tabview {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.tabs = this.el.querySelector('.tabview__tabs')
    this.tabsCarousel = new Swiper(this.tabs.querySelector('.swiper-container'), {
      ...CAROUSEL_OPTIONS,
      pagination: false,
      navigation: {
        prevEl: '.tabview__arrow--prev',
        nextEl: '.tabview__arrow--next'
      },
      freeMode: true,
      freeModeSticky: true,
      slidesPerView: 2,
      breakpoints: {
        768: {
          slidesPerView: 4
        },
        1024: {
          slidesPerView: 7
        }
      }
    })
    if( this.tabs ){
      this.panes = this.el.querySelector('.tabview__panes')
      this.currentTab = this.tabs.querySelector('.tabview__tab.active')
      this.currentPane = this.panes.querySelector('.tabview__pane.active')

      this.tabs.addEventListener('click', this.handleTabsClick)
    }
  }

  set current(index) {
    const tab = this.tabs.querySelector(`.tabview__tab[data-index="${index}"]`)
    const pane = this.panes.querySelector(`.tabview__pane[data-index="${index}"]`)
    this.currentTab.classList.remove('active')
    this.currentPane.classList.remove('active')
    this.currentTab = tab
    this.currentPane = pane
    this.currentTab.classList.add('active')
    this.currentPane.classList.add('active')

    if (typeof this.options.onChange === 'function') {
      this.options.onChange(index)
    }
  }

  handleTabsClick = ({ target }) => {
    const tab = target.closest('.tabview__tab')
    if (!tab) return
    this.current = tab.dataset.index
  }
}

export default Tabview
