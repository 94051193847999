import Swiper, { Navigation, Pagination } from 'swiper'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { CAROUSEL_OPTIONS, DEFAULT_EASING } from '../../config'

Swiper.use([Navigation, Pagination])
gsap.registerPlugin(ScrollTrigger)

class Story {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.ui = {
      carousel: this.el.querySelector('.carousel')
    }
    this.carousel = new Swiper(this.ui.carousel, CAROUSEL_OPTIONS)
    this.fragments = Array.from(this.el.querySelectorAll('.story__fragment'))

    if (this.options.animateOnScroll) {
      this.scrollAnimation = ScrollTrigger.create({
        trigger: this.el,
        start: 'bottom bottom',
        end: `+=${this.el.clientHeight * this.fragments.length}`,
        pin: true,
        scrub: true,
        invalidateOnRefresh: true,
        animation: this.animate()
      })
    }
  }

  animate() {
    const timeline = gsap.timeline()
    const [first, ...rest] = this.fragments

    timeline.to(first, {
      autoAlpha: 0,
      duration: 45,
      ease: DEFAULT_EASING
    }, '+=60')

    rest.forEach((fragment, i) => {
      timeline.fromTo(fragment.children, {
        y: 48,
        autoAlpha: 0
      }, {
        y: 0,
        autoAlpha: 1,
        duration: 45,
        ease: DEFAULT_EASING
      })
      timeline.to(fragment, {
        autoAlpha: i < (rest.length - 1) ? 0 : 1,
        duration: 45,
        ease: DEFAULT_EASING
      }, '+=60')
    })

    return timeline
  }
}

export default Story
