class FixedBanner {

  constructor() {
    this.banner = document.querySelector('.fixed-banner')
    const closeButton = document.querySelector('.js-close-fixed-banner')

    if (closeButton) {
      closeButton.addEventListener('click', () => {
        this.banner.classList.add('is-hide')
      })
    }

    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY

      if (scrollPos > 50) {
        this.banner.classList.add('is-small')
      } else {
        this.banner.classList.remove('is-small')
      }
    })

    this.checkFormatBanner()

    window.addEventListener('resize', () => {
      this.checkFormatBanner()
    })
  }

  checkFormatBanner() {

    if (window.innerWidth >= 768) {
      this.banner.classList.remove('is-small')
    } else {
      this.banner.classList.add('is-small')
    }

  }

}

export default FixedBanner
