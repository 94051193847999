
const AUTOPLAY_STOP_TIMER = 5000

class Gif {
    constructor ( $gifCont ) {
        this.$cont = $gifCont
        this.$btn = this.$cont.querySelector('.js-gif-toggle')
        this.isActive = this.$cont.classList.contains('--gif-play')
        this.timer
        
        this.$btn.addEventListener('click', event => {
            event.preventDefault()
            this.toggle()
        })

        const options = {
            threshold: 1.0
        }

        const callback = entries => {
            entries.forEach(async entry => {
                if (entry.isIntersecting) {
                    this.play()
                }
            })
        }
          
        const  observer = new IntersectionObserver(callback, options)
        observer.observe(this.$cont)
    }

    setTimer () {
        return setTimeout(() => {
            this.timer && this.pause()
        }, AUTOPLAY_STOP_TIMER)
    }

    play () {
        this.timer = clearTimeout(this.timer)
        this.isActive = true
        this.$cont.classList.add('--gif-play')
        this.$btn.classList.add('--active')
        this.timer = this.setTimer()
    }

    pause () {
        this.timer = clearTimeout(this.timer)
        this.isActive = false
        this.$cont.classList.remove('--gif-play')
        this.$btn.classList.remove('--active')
    }

    toggle () {
        if( this.isActive ){
            this.pause()
        } else {
            this.play()
        }
    }
}

export default Gif
