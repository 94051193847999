import AnchoredSections from '../../components/AnchoredSections'
import Story from '../../components/Story'
import Video from '../../components/Video'
import Visual from '../../components/Visual'

class Solutions {
  constructor() {
    this.anchorsManager = new AnchoredSections(document.querySelector('.anchored-sections'))
    this.stories = Array.from(document.querySelectorAll('.story')).map(el => {
      // switch animateOnScroll to true to let
      // stories to pin and to animate on scroll
      return new Story(el, { animateOnScroll: true })
    })
    this.visuals = Array.from(document.querySelectorAll('.visual')).map(el => new Visual(el))
    new Video()
  }
}

export default Solutions
