import AccordionItem from './AccordionItem'

class Accordion {
  constructor(el, options = {}) {
    this.el = el
    this.options = options
    this.items = Array.from(this.el.querySelectorAll('.accordion__item')).map(item => {
      return new AccordionItem(item, { onExpand: this.collapseAll })
    })
  }

  expandAll = (animate = true) => {
    this.items.forEach(item => item.expand(animate))
  }

  collapseAll = () => {
    this.items.forEach(item => item.collapse())
  }
}

export default Accordion
