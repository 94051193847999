import Submenu from './Submenu'

class Menu {
  constructor(el) {
    this.el = el
    this.hamburger = this.el.querySelector('.menu__hamburger')
    this.submenus = Array.from(this.el.querySelectorAll('[data-subnav]')).map(entry => new Submenu(entry))

    this.hamburger.addEventListener('click', this.handleHambugerClick)
  }

  handleHambugerClick = () => {
    if (this.el.classList.contains('open')) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.el.classList.add('open')
  }

  close() {
    this.el.classList.remove('open')
  }

  reset() {
    this.close()
    this.submenus.forEach(submenu => submenu.reset())
  }
}

export default Menu
