class Modal {
  constructor(el) {
    this.el = el
    this.el.addEventListener('click', this.handleClicks)
  }

  handleClicks = ({ target }) => {
    if (target.closest('.modal__close') || target.closest('.js-close-modal')) {
      this.close()
    }
  }

  open() {
    this.el.classList.add('open')
  }

  close() {
    this.el.classList.remove('open')
  }
}

export default Modal
