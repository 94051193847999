import * as controllers from './pages'
import Adv from './pages/AdvancedCampaigns'
import Menu from '../components/Menu'
import Modal from '../components/Modal'
import Reveal from '../helpers/reveal'
import { DESKTOP_BREAKPOINT } from '../config'

class Application {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    this.props = props
    this.menu = new Menu(document.getElementById('main-menu'))
    this.loginModal = new Modal(document.getElementById('login-disclaimer'))
    this.loginModalTriggers = document.querySelectorAll('.js-login')
    this.device = window.innerWidth < 1024 ? 'mobile' : 'desktop'
    this.onResizeEnd = null

    this.init()
    this.show()

    this.loginModalTriggers.forEach(trigger => {
      trigger.addEventListener('click', this.handleLoginModalOpening)
    })

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('beforeunload', this.hide)
  }

  init() {
    const { ctrl: page } = document.body.dataset
    const Ctrl = controllers[page]
    try {
      this.page = new Ctrl()
    } catch (err) {
      console.log(err)
    }
  }

  show() {
    document.body.classList.remove('hidden')
    Reveal.init()
  }

  hide() {
    document.body.classList.add('hidden')
  }

  handleLoginModalOpening = e => {
    if (window.innerWidth < DESKTOP_BREAKPOINT) {
      e.preventDefault()
      this.loginModal.open()
    }
  }

  handleResize = () => {
    const device = window.innerWidth < 1024 ? 'mobile' : 'desktop'
    if (device === this.device) return
    this.menu.reset()
    if (this.onResizeEnd) clearTimeout(this.onResizeEnd)
    document.body.classList.add('resizing')
    this.onResizeEnd = setTimeout(() => {
      this.device = device
      document.body.classList.remove('resizing')
    }, 200)
  }
}

let instance

export default {
  init: props => {
    if (!instance) {
      instance = new Application(props)
    }
  },
  get: () => instance
}
