import Accordion from '../../components/Accordion'

class CondizioniGenerali {
  constructor() {
    this.faqGroups = Array.from(document.querySelectorAll('.accordion')).map(accordion => {
      return new Accordion(accordion)
    })
  }
}

export default CondizioniGenerali