import FeaturedHero from '../../components/FeaturedHero'
import Visual from '../../components/Visual'
import FixedBanner from '../../components/FixedBanner'
import Carousel from '../../components/Carousel'

class Homepage {
  constructor() {
    this.sections = {
      hero: document.querySelector('.hero'),
      visual: document.querySelector('.visual')
    }

    this.hero = new FeaturedHero(this.sections.hero, { revealDelay: 4.25 })
    this.visual = new Visual(this.sections.visual)
    new FixedBanner()
    new Carousel()
  }
}

export default Homepage
