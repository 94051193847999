import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper'
Swiper.use([Pagination, EffectFade, Autoplay])

import { DESKTOP_BREAKPOINT, CAROUSEL_OPTIONS } from '../../config'

class Carousel {

  constructor() {
    this.carousel = document.querySelector('.js-carousel-inspirations')
    const windowWidth = window.innerWidth
    this.swiper
    this.swiperCarousel

    if (windowWidth < DESKTOP_BREAKPOINT) {
      this.initFilters()
    } else {
      this.initClickFilter()
      this.setPositionCarousel()
    }

    window.addEventListener('resize', () => {
      const getWindowWidth = window.innerWidth

      if (getWindowWidth < DESKTOP_BREAKPOINT) {
        this.initFilters()
      } else {
        if (this.swiper) {
          this.swiper.destroy()
        }
        this.initClickFilter()
        this.setPositionCarousel()
      }
    })

    this.initSlider()
  }

  initSlider() {

    this.swiperCarousel = new Swiper(this.carousel, {
      slidesPerView: 1,
      navigation: {
        nextEl: '.carousel-inspirations__arrow--next',
        prevEl: '.carousel-inspirations__arrow--prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 24
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })

  }

  initFilters() {
    const filters = document.querySelector('.js-carousel-filters')
    if (filters) {
      this.swiper = new Swiper(filters, {
        navigation: {
          nextEl: '.carousel-filters__arrow--next',
          prevEl: '.carousel-filters__arrow--prev'
        }
      })
    }
  }

  initClickFilter() {
    const filters = document.querySelectorAll('.js-change-slider')
    const slides = document.querySelectorAll('.swiper-slide')

    if (filters) {
      filters.forEach(el => {
        el.addEventListener('click', () => {
          const ref = el.getAttribute('data-ref')
          filters.forEach(el2 => {
            el2.classList.remove('is-active')
          })
          el.classList.add('is-active')
          if (slides) {
            slides.forEach(slide => {
              const cat = slide.getAttribute('data-filter')
              if (cat !== ref && !slide.classList.contains('swiper-default')) {
                slide.classList.remove('swiper-slide')
                slide.classList.add('non-swiper-slide')
              } else {
                slide.classList.add('swiper-slide')
                slide.classList.remove('non-swiper-slide')
              }
            })
          }
          this.swiperCarousel.destroy()
          this.initSlider()
        })
      })
    }
  }

  setPositionCarousel() {
    const positionLeft = document.querySelector('.first-el-position').offsetLeft

    document.querySelector('.carousel-container').style.transform = 'translateX(' + positionLeft + 'px)'
  }


}

export default Carousel
