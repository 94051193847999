import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * It initializes the reveal animation over single items
 */
function revealSingleItems() {
  document.querySelectorAll('[data-reveal]').forEach(item => {
    ScrollTrigger.create({
      trigger: item.parentNode,
      once: true,
      toggleClass: { targets: item, className: 'revealed' }
    })
  })
}

/**
 * It initializes the reveal animation over grouped items (staggered)
 */
function revealGroupedItems() {
  document.querySelectorAll('[data-reveal-group]').forEach(group => {
    ScrollTrigger.batch(group.children, {
      once: true,
      onEnter: batch => gsap.to(batch, {
        stagger: {
          each: 0.1,
          onStart() {
            this.targets()[0].classList.add('revealed')
          }
        }
      })
    })
  })
}

export default {
  init: () => {
    revealSingleItems()
    revealGroupedItems()
  }
}
