
class Video {
    constructor () {
        const $videoToggles = document.querySelectorAll('.js-video-toggle')

        $videoToggles.forEach($btn => {
            const $videoCont = $btn.closest('.video-container')

            if( $videoCont ){
                const $video = $videoCont.querySelector('video')
                let isActive = $btn.classList.contains('--active')

                $btn.addEventListener('click', event => {
                    event.preventDefault()
                    
                    $btn.classList.toggle('--active')
                    isActive = !isActive
                    
                    if( isActive ){
                        $video.play()
                    } else {
                        $video.pause()
                    }
                })
            }
        })
    }
}

export default Video
