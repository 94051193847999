import gsap from 'gsap'
import { DEFAULT_DURATION, DEFAULT_EASING } from '../../config'

class Visual {
  constructor(el) {
    this.el = el
    this.id = this.el.id
    this.key = this.el.dataset.key
    this.canvas = this.el.querySelector('.visual__canvas')
    this.overlay = this.el.querySelector('.visual__overlay')

    try {
      const { threshold = '0.75' } = this.el.dataset
      this.composition = window.AdobeAn.getComposition(this.key)
      this.observer = new IntersectionObserver(this.handleIntersection, { threshold: parseFloat(threshold) })

      window.createjs.MotionGuidePlugin.install()

      this.library = this.composition.getLibrary()
      this.spriteSheet = this.composition.getSpriteSheet()
      this.exportRoot = new this.library[this.id]()
      this.stage = new this.library.Stage(this.canvas)
      this.stage.addChild(this.exportRoot)

      window.AdobeAn.compositionLoaded(this.library.properties.id)
      window.createjs.Ticker.setFPS(this.library.properties.fps)
      window.addEventListener('resize', this.resize)

      this.resize()
      this.observer.observe(this.el)
    } catch (err) {
      console.log(err)
    }
  }

  resize = () => {
    const { width: nativeWidth } = this.library.properties
    const pixelRatio = window.devicePixelRatio || 1
    const scaleRatio = this.el.clientWidth / nativeWidth
    const scaleDelta = scaleRatio * pixelRatio

    this.canvas.width = this.el.clientWidth * pixelRatio
    this.canvas.height = this.el.clientHeight * pixelRatio
    this.canvas.style.width = `${this.el.clientWidth}px`
    this.canvas.style.height = `${this.el.clientHeight}px`
    this.stage.scaleX = scaleDelta
    this.stage.scaleY = scaleDelta
    this.stage.tickOnUpdate = false
    this.stage.update()
    this.stage.tickOnUpdate = true
  }

  animate = () => {
    gsap.to(this.el, {
      opacity: 1,
      duration: DEFAULT_DURATION,
      ease: DEFAULT_EASING,
      onComplete: () => {
        window.createjs.Ticker.addEventListener('tick', this.stage)
      }
    })
  }

  handleIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.animate()
      }
    })
  }
}

export default Visual
